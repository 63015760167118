import React, { useState, useEffect, useContext } from "react";
import { Link } from "gatsby";
import Button from "@material-ui/core/Button";
import { AppContext } from "../../contexts/AppContext";
import HeroApp from "../../images/app/hero_app.png";
import HeroCover from "../../images/cover.jpg";

function Banner() {
    const { appState } = useContext(AppContext);

    const slides = [
        {
            image: HeroApp,
            title: appState.i18n["banner"]["appPromo"],
            subtext: "eVaz Energy APP",
            button: {
                text: "Download now!",
                link: "#app-promo",
            },
        },
        {
            image: HeroCover,
            title: appState.i18n["banner"]["title"],
            subtext: appState.i18n["banner"]["subtext"],
            button: {
                text: appState.i18n["get_card"].toUpperCase(),
                link: "/register",
            },
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentSlide((prev) => (prev + 1) % slides.length);
                setIsFading(false);
            }, 500);
        }, 10000);
        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div
            id="start"
            className={`Banner-cont ${isFading ? "fading" : ""}`}
            style={{
                backgroundImage: `url(${slides[currentSlide].image})`,
            }}
        >
            <div className="banner-text-container">
                <h1 className="banner-title text-shadow">{slides[currentSlide].title}</h1>
                <h2 className="banner-subtext text-shadow">{slides[currentSlide].subtext}</h2>
                <Link className="banner-gotoregister-link" to={slides[currentSlide].button.link}>
                    <Button className="button-default shadow-lightBG" variant="contained">
                        {slides[currentSlide].button.text}
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default Banner;
