import React, { useContext } from "react";

import googlePlay from "../../images/app/google_play.png";
import appStore from "../../images/app/app_store.png";
import appPromo from "../../images/app/app_promo.png";

import { AppContext } from "../../contexts/AppContext";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.default.colors.veryLightGrey};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 100px 0px 0 0 ;
`;

const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 15px; /* Adiciona padding lateral para evitar texto fora do ecrã */
    text-align: center; /* Garante que o texto esteja centralizado */

    .section_title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            font-size: 1.5rem; /* Reduz tamanho no mobile */
        }
    }

    .secondary-text {
        font-size: 1rem;
        color: #555;
        line-height: 1.5; /* Melhora legibilidade */
        word-wrap: break-word; /* Evita que palavras longas saiam do ecrã */

        @media (max-width: 768px) {
            font-size: 0.875rem; /* Ajusta o tamanho do texto no mobile */
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;

    .app-button {
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    img {
        max-width: 200px;
        height: auto;
    }
`;

const AppImage = styled.div`
    margin-top: 30px;

    img {
        max-width: 100%;
        height: auto;
    }
`;

function AppPromo() {
    const { appState } = useContext(AppContext);

    return (
        <Wrapper id="app-promo">
            <CardInfo>
                <span className="card-title title section_title">eVaz Energy APP</span>
                <span className="card-text secondary-text">                  { appState.i18n["banner"]["appPromo"] }
                </span>
            </CardInfo>

            <ButtonContainer>
                <a href="https://apps.apple.com/pt/app/evaz-energy/id6739589936" target="_blank" rel="noopener noreferrer">
                    <img className="app-button" src={appStore} alt="eVaz Energy App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.evazenergy.app" target="_blank" rel="noopener noreferrer">
                    <img className="app-button" src={googlePlay} alt="eVaz Energy Google Play" />
                </a>
            </ButtonContainer>

            <AppImage>
                <img src={appPromo} alt="eVaz Energy APP" />
            </AppImage>
        </Wrapper>
    );
}

export default AppPromo;
