import React, { useReducer } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CardPromo from "../components/main/CardPromo"
import Plans from "../components/main/Plans"
import BestSolutions from "../components/main/BestSolutions";
import Banner from "../components/main/Banner";
import About from "../components/main/About";
import Partners from "../components/main/Partners";
import Contacts from "../components/main/ContactUs";
import Footer from "../components/layout/Footer";
import Powerpacks from "../components/main/Powerpacks";
import PrivateSolutions from "../components/main/PrivateSolutions"
import PublicSolutions from "../components/main/PublicSolutions"
import AppPromo from "../components/main/AppPromo"




const IndexPage = () => {


  return <Layout>
      <Banner />
      <AppPromo />
      <CardPromo />
      {/* <BestSolutions /> */}
      {/* <Powerpacks /> */}
      {/* <Plans /> */}

      <PrivateSolutions />
      <PublicSolutions inverted/>

      <About />
      <Partners />
      <Contacts />
      <footer>
        <Footer />
      </footer>
      {/* <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </Layout>
}

export default IndexPage
