import React, { useContext } from "react";
import electric_solutions from "../../images/electric_solutions.png";
import { AppContext } from "../../contexts/AppContext";

function BestSolutions() {
    const { appState } = useContext(AppContext);

    return (
        <div className="BestSolutions-cont">
            <div id="services"></div>
            <div className="solutions-img-cont">
                <img src={electric_solutions} className="solutions-img" alt="best solutions" />
            </div>
            <div className="hbox solutions-content">
                <div className="solutions-title">{appState.i18n["solutions"]["title"].toUpperCase()}</div>
                <div className="solutions-text secondary-text">{appState.i18n["solutions"]["text"]}</div>
            </div>
        </div>
    );
}

export default BestSolutions;